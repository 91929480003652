import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { UserAuth } from '../domain/userAuth';

export const useRouteSecurization = (allowedRoles: string[]) => {
  const { accessToken } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      const user = new UserAuth(accessToken);

      const userRole = user.getRole();
      if (!allowedRoles.includes(userRole)) {
        navigate('/credentials');
      }
    }
  }, [accessToken]);
};
